import * as React from "react";
import {css} from "@emotion/react";

import {Button} from "@pg-design/button-module";
import {w100} from "@pg-design/helpers-css";

interface IProps {
    onCancel: () => void;
    onSave: (e: React.MouseEvent<HTMLElement>) => void;
    submitText?: string;
    clearText?: string;
}

export const FilterFooter: React.FunctionComponent<IProps> = ({onSave, onCancel, submitText = "Wybierz", clearText = "Wyczyść"}) => {
    return (
        <div css={filterFooter}>
            <Button variant="filled_primary" onClick={onSave} size="small" css={fullWidthButton}>
                {submitText}
            </Button>

            <Button variant="none_secondary" onClick={onCancel} size="small" css={w100}>
                {clearText}
            </Button>
        </div>
    );
};

const filterFooter = css`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
`;

const fullWidthButton = css`
    width: 100%;
`;
import * as React from "react";
import {css} from "@emotion/react";

import {Button} from "@pg-design/button-module";
import {w100} from "@pg-design/helpers-css";

interface IProps {
    onCancel: () => void;
    onSave: (e: React.MouseEvent<HTMLElement>) => void;
    submitText?: string;
    clearText?: string;
}

export const FilterFooter: React.FunctionComponent<IProps> = ({onSave, onCancel, submitText = "Wybierz", clearText = "Wyczyść"}) => {
    return (
        <div css={filterFooter}>
            <Button variant="filled_primary" onClick={onSave} size="small" css={fullWidthButton}>
                {submitText}
            </Button>

            <Button variant="none_secondary" onClick={onCancel} size="small" css={w100}>
                {clearText}
            </Button>
        </div>
    );
};

const filterFooter = css`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
`;

const fullWidthButton = css`
    width: 100%;
`;
