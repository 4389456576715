import React from "react";
import {css} from "@emotion/react";

import {mb, mt, onDesktop, p} from "@pg-design/helpers-css";
import {Radio} from "@pg-design/radio-module";

import {FilterFooter} from "../../../../search/components/filters/SearchFiltersFooter";

interface SortOption {
    label: string;
    value: number;
    slug?: string;
}

interface IOwnProps<T> {
    currentValue: T;
    setCurrentValue: (value: number) => void;
    sortOptions: SortOption[];
    onCancel: () => void;
    onSave: () => void;
}

export const SortOptions = <T,>(props: IOwnProps<T>) => {
    return (
        <div css={sortHolder}>
            <div css={sortOptionsHolder}>
                {props.sortOptions.map((sortOption) => {
                    return (
                        <Radio
                            key={sortOption.value}
                            id={sortOption.value.toString()}
                            name={sortOption.label}
                            labelContent={sortOption.label}
                            isActive={sortOption.value === props.currentValue}
                            value={sortOption.value}
                            onChange={() => {
                                props.setCurrentValue(sortOption.value);
                            }}
                        />
                    );
                })}
            </div>
            <FilterFooter onCancel={props.onCancel} onSave={props.onSave} />
        </div>
    );
};

const sortHolder = css`
    height: calc(100vh - 6rem);
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    ${mt(2)};
    ${p(0, 3, 2, 3)};

    ${onDesktop(css`
        height: unset;
        display: block;
        padding: 0;
    `)};
`;

const sortOptionsHolder = css`
    display: flex;
    flex-direction: column;
    align-items: start;
    flex-shrink: 0;
    flex-grow: 0;

    > div {
        ${mb(2)};
    }
`;
import React from "react";
import {css} from "@emotion/react";

import {mb, mt, onDesktop, p} from "@pg-design/helpers-css";
import {Radio} from "@pg-design/radio-module";

import {FilterFooter} from "../../../../search/components/filters/SearchFiltersFooter";

interface SortOption {
    label: string;
    value: number;
    slug?: string;
}

interface IOwnProps<T> {
    currentValue: T;
    setCurrentValue: (value: number) => void;
    sortOptions: SortOption[];
    onCancel: () => void;
    onSave: () => void;
}

export const SortOptions = <T,>(props: IOwnProps<T>) => {
    return (
        <div css={sortHolder}>
            <div css={sortOptionsHolder}>
                {props.sortOptions.map((sortOption) => {
                    return (
                        <Radio
                            key={sortOption.value}
                            id={sortOption.value.toString()}
                            name={sortOption.label}
                            labelContent={sortOption.label}
                            isActive={sortOption.value === props.currentValue}
                            value={sortOption.value}
                            onChange={() => {
                                props.setCurrentValue(sortOption.value);
                            }}
                        />
                    );
                })}
            </div>
            <FilterFooter onCancel={props.onCancel} onSave={props.onSave} />
        </div>
    );
};

const sortHolder = css`
    height: calc(100vh - 6rem);
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    ${mt(2)};
    ${p(0, 3, 2, 3)};

    ${onDesktop(css`
        height: unset;
        display: block;
        padding: 0;
    `)};
`;

const sortOptionsHolder = css`
    display: flex;
    flex-direction: column;
    align-items: start;
    flex-shrink: 0;
    flex-grow: 0;

    > div {
        ${mb(2)};
    }
`;
