export enum SortOptionsValues {
    NOT_SELECTED,
    LOWEST_PRICE,
    HIGHEST_PRICE,
    LOWEST_FLOOR,
    HIGHEST_FLOOR,
    LOWEST_ROOMS_NUMBER,
    HIGHEST_ROOMS_NUMBER
}
export enum SortOptionsValues {
    NOT_SELECTED,
    LOWEST_PRICE,
    HIGHEST_PRICE,
    LOWEST_FLOOR,
    HIGHEST_FLOOR,
    LOWEST_ROOMS_NUMBER,
    HIGHEST_ROOMS_NUMBER
}
