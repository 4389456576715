import React from "react";
import classNames from "classnames";

import {useBlurOutside} from "@pg-mono/hooks";

import {InputRangeOptions} from "./InputRangeOptions";

import {displayNone, inputHolder, inputOptionsWrapper, rangeHolder, separatorStyle} from "./create_range.module.css";

export interface IRange<T> {
    lower: T;
    upper: T;
    bounds?: string;
}

export enum Direction {
    UP = "UP",
    DOWN = "DOWN"
}

type RangeType = string | number;

interface IFieldProps<TKey, TValue> {
    name?: TKey;
    value?: TValue;
    error?: string | string[];
    onChange?: React.ChangeEventHandler<HTMLInputElement>;
    checked: boolean;
}

interface ICreateRangeInnerComponentProps extends Omit<IFieldProps<string, React.InputHTMLAttributes<HTMLInputElement>["value"]>, "checked"> {
    roundingDirection?: Direction;
    roundToMultiplicationOf?: number;
    groupClassName?: string;
    placeholder?: string;
    type?: string;
    pattern?: string;
    maxNumberLength?: number;
    className?: string;
    onKeyPress?: React.KeyboardEventHandler<HTMLInputElement>;
    min?: number | string;
}

export interface RangeProps extends Omit<IFieldProps<string, IRange<RangeType>>, "checked" | "onChange"> {
    disabled?: boolean;
    className?: string;
    groupClassName?: string;
    options?: {value: number; label: string}[];
    hasDashSeparator?: boolean;
    errorOnBottom?: boolean;
    roundToMultiplicationOf?: number;
    onChange: (fieldName: string, value: IRange<RangeType>) => void;
}

export const createRange = (InnerComponent: React.ComponentType<ICreateRangeInnerComponentProps>) => (props: RangeProps) => {
    const onChange = (name: string, value: RangeType) => {
        const currentValue: IRange<RangeType> = {
            lower: props.value?.lower || "",
            upper: props.value?.upper || "",
            bounds: props.value?.bounds
        };

        if (name === `${props.name}__lower`) {
            props.onChange(`${props.name}`, {...currentValue, lower: value});
        }
        if (name === `${props.name}__upper`) {
            props.onChange(`${props.name}`, {...currentValue, upper: value});
        }
    };

    const createOnChange =
        (name: string): ICreateRangeInnerComponentProps["onChange"] =>
        (event) => {
            const value = event.currentTarget.value;
            onChange(name, value);
        };

    const preventNonDigitChars = (event: React.KeyboardEvent) => {
        const notAllowedCharCodes = ["Minus", "Period", "NumpadAdd", "NumpadSubtract", "Comma", "Equal"];

        if (notAllowedCharCodes.includes(event.code)) {
            event.preventDefault();
        }
    };

    const {name, value} = props;

    const {
        isFocused: isRangeInputLowerFocused,
        setIsFocused: setRangeInputLowerIsFocused,
        wrapperProps: inputLowerWrapperProps,
        targetElementProps: inputLowerBlurProps
    } = useBlurOutside<HTMLDivElement>();
    const {
        isFocused: isRangeInputUpperFocused,
        setIsFocused: setRangeInputUpperIsFocused,
        wrapperProps: inputUpperWrapperProps,
        targetElementProps: inputUpperBlurProps
    } = useBlurOutside<HTMLDivElement>();

    const errors = [props.error?.[0] ? [props.error[0]] : undefined, props.error?.[1] ? [props.error[1]] : undefined];

    return (
        <div className={classNames(rangeHolder, props.className)}>
            <div {...inputLowerWrapperProps} className={classNames(inputHolder, "range-input-lower")}>
                <InnerComponent
                    {...inputLowerBlurProps}
                    name={`${name}__lower`}
                    value={value && value.lower}
                    error={errors[0]}
                    onChange={createOnChange(`${name}__lower`)}
                    className={props.groupClassName}
                    placeholder="Od"
                    type="number"
                    pattern="[0-9]*"
                    min="0"
                    onKeyPress={preventNonDigitChars}
                />
                <InputRangeOptions
                    value={props.value}
                    options={props.options}
                    onChange={onChange}
                    onClick={() => setRangeInputLowerIsFocused(false)}
                    name={`${name}__lower`}
                    cutDirection={1}
                    className={classNames(inputOptionsWrapper, isRangeInputLowerFocused ? "" : displayNone)}
                />
            </div>

            {props.hasDashSeparator && <span className={separatorStyle}>-</span>}

            <div {...inputUpperWrapperProps} className={classNames(inputHolder, "range-input-upper")}>
                <InnerComponent
                    {...inputUpperBlurProps}
                    name={`${name}__upper`}
                    value={value && value.upper}
                    error={errors[1]}
                    onChange={createOnChange(`${name}__upper`)}
                    className={props.groupClassName}
                    placeholder="Do"
                    type="number"
                    pattern="[0-9]*"
                    min="0"
                    onKeyPress={preventNonDigitChars}
                />
                <InputRangeOptions
                    value={props.value}
                    options={props.options}
                    onChange={onChange}
                    onClick={() => setRangeInputUpperIsFocused(false)}
                    name={`${name}__upper`}
                    cutDirection={-1}
                    className={classNames(inputOptionsWrapper, isRangeInputUpperFocused ? "" : displayNone)}
                />
            </div>
        </div>
    );
};
import React from "react";
import classNames from "classnames";

import {useBlurOutside} from "@pg-mono/hooks";

import {InputRangeOptions} from "./InputRangeOptions";

import {displayNone, inputHolder, inputOptionsWrapper, rangeHolder, separatorStyle} from "./create_range.module.css";

export interface IRange<T> {
    lower: T;
    upper: T;
    bounds?: string;
}

export enum Direction {
    UP = "UP",
    DOWN = "DOWN"
}

type RangeType = string | number;

interface IFieldProps<TKey, TValue> {
    name?: TKey;
    value?: TValue;
    error?: string | string[];
    onChange?: React.ChangeEventHandler<HTMLInputElement>;
    checked: boolean;
}

interface ICreateRangeInnerComponentProps extends Omit<IFieldProps<string, React.InputHTMLAttributes<HTMLInputElement>["value"]>, "checked"> {
    roundingDirection?: Direction;
    roundToMultiplicationOf?: number;
    groupClassName?: string;
    placeholder?: string;
    type?: string;
    pattern?: string;
    maxNumberLength?: number;
    className?: string;
    onKeyPress?: React.KeyboardEventHandler<HTMLInputElement>;
    min?: number | string;
}

export interface RangeProps extends Omit<IFieldProps<string, IRange<RangeType>>, "checked" | "onChange"> {
    disabled?: boolean;
    className?: string;
    groupClassName?: string;
    options?: {value: number; label: string}[];
    hasDashSeparator?: boolean;
    errorOnBottom?: boolean;
    roundToMultiplicationOf?: number;
    onChange: (fieldName: string, value: IRange<RangeType>) => void;
}

export const createRange = (InnerComponent: React.ComponentType<ICreateRangeInnerComponentProps>) => (props: RangeProps) => {
    const onChange = (name: string, value: RangeType) => {
        const currentValue: IRange<RangeType> = {
            lower: props.value?.lower || "",
            upper: props.value?.upper || "",
            bounds: props.value?.bounds
        };

        if (name === `${props.name}__lower`) {
            props.onChange(`${props.name}`, {...currentValue, lower: value});
        }
        if (name === `${props.name}__upper`) {
            props.onChange(`${props.name}`, {...currentValue, upper: value});
        }
    };

    const createOnChange =
        (name: string): ICreateRangeInnerComponentProps["onChange"] =>
        (event) => {
            const value = event.currentTarget.value;
            onChange(name, value);
        };

    const preventNonDigitChars = (event: React.KeyboardEvent) => {
        const notAllowedCharCodes = ["Minus", "Period", "NumpadAdd", "NumpadSubtract", "Comma", "Equal"];

        if (notAllowedCharCodes.includes(event.code)) {
            event.preventDefault();
        }
    };

    const {name, value} = props;

    const {
        isFocused: isRangeInputLowerFocused,
        setIsFocused: setRangeInputLowerIsFocused,
        wrapperProps: inputLowerWrapperProps,
        targetElementProps: inputLowerBlurProps
    } = useBlurOutside<HTMLDivElement>();
    const {
        isFocused: isRangeInputUpperFocused,
        setIsFocused: setRangeInputUpperIsFocused,
        wrapperProps: inputUpperWrapperProps,
        targetElementProps: inputUpperBlurProps
    } = useBlurOutside<HTMLDivElement>();

    const errors = [props.error?.[0] ? [props.error[0]] : undefined, props.error?.[1] ? [props.error[1]] : undefined];

    return (
        <div className={classNames(rangeHolder, props.className)}>
            <div {...inputLowerWrapperProps} className={classNames(inputHolder, "range-input-lower")}>
                <InnerComponent
                    {...inputLowerBlurProps}
                    name={`${name}__lower`}
                    value={value && value.lower}
                    error={errors[0]}
                    onChange={createOnChange(`${name}__lower`)}
                    className={props.groupClassName}
                    placeholder="Od"
                    type="number"
                    pattern="[0-9]*"
                    min="0"
                    onKeyPress={preventNonDigitChars}
                />
                <InputRangeOptions
                    value={props.value}
                    options={props.options}
                    onChange={onChange}
                    onClick={() => setRangeInputLowerIsFocused(false)}
                    name={`${name}__lower`}
                    cutDirection={1}
                    className={classNames(inputOptionsWrapper, isRangeInputLowerFocused ? "" : displayNone)}
                />
            </div>

            {props.hasDashSeparator && <span className={separatorStyle}>-</span>}

            <div {...inputUpperWrapperProps} className={classNames(inputHolder, "range-input-upper")}>
                <InnerComponent
                    {...inputUpperBlurProps}
                    name={`${name}__upper`}
                    value={value && value.upper}
                    error={errors[1]}
                    onChange={createOnChange(`${name}__upper`)}
                    className={props.groupClassName}
                    placeholder="Do"
                    type="number"
                    pattern="[0-9]*"
                    min="0"
                    onKeyPress={preventNonDigitChars}
                />
                <InputRangeOptions
                    value={props.value}
                    options={props.options}
                    onChange={onChange}
                    onClick={() => setRangeInputUpperIsFocused(false)}
                    name={`${name}__upper`}
                    cutDirection={-1}
                    className={classNames(inputOptionsWrapper, isRangeInputUpperFocused ? "" : displayNone)}
                />
            </div>
        </div>
    );
};
