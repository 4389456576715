import React from "react";
import {useEffect, useState} from "react";
import {css} from "@emotion/react";
import styled from "@emotion/styled";
import {lighten} from "polished";

import {calculateRemSize, w100} from "@pg-design/helpers-css";
import {IFormFieldProps} from "@pg-mono/form";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface IProps extends IFormFieldProps<string, any> {
    label?: JSX.Element | string;
    groupClassName?: string;
    isActive: boolean;
    isNonActive: boolean;
    isDisabled?: boolean;
    propertyConfiguratorInput?: boolean;
}

export const SearchFiltersRoomsCheckbox = (props: IProps) => {
    const [triggerOnAfterChange, setTriggerOnAfterChange] = useState(false);
    useEffect(() => {
        if (typeof props.onAfterChange === "function" && triggerOnAfterChange) {
            setTriggerOnAfterChange(false);
            props.onAfterChange(props.name, props.value);
        }
    }, [props.onAfterChange, triggerOnAfterChange]);

    const onChange = () => {
        if (props.isNonActive) {
            return;
        }

        if (props.isDisabled) {
            return;
        }
        props.onChange(props.name, props.value);
        setTriggerOnAfterChange(false);
    };

    return (
        <CheckboxHolder isActive={props.isActive} isNonActive={props.isNonActive} isDisabled={props.isDisabled} onClick={onChange}>
            <span>{props.label}</span>
        </CheckboxHolder>
    );
};

interface ICheckboxHolder {
    isActive: boolean;
    isNonActive: boolean;
    isDisabled?: boolean;
    propertyConfiguratorInput?: boolean;
}

const CheckboxHolder = styled.div<ICheckboxHolder>`
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    ${(props) =>
        props.isDisabled
            ? css`
                  cursor: not-allowed;
                  color: #ccc;
              `
            : css`
                  cursor: pointer;
              `}
    background: ${(props) => props.isActive && (props.isNonActive ? "#ececec" : props.theme.colors.primary)};

    @media (min-width: ${(props) => props.theme.breakpoints.md}) {
        &:hover {
            background: ${(props) => (props.isActive ? (props.isNonActive ? "#f2f2f2" : lighten(0.05, "#ccc")) : "#f9f9f9")};
        }
    }
    ${({propertyConfiguratorInput, theme}) =>
        propertyConfiguratorInput &&
        css`
            height: ${calculateRemSize(6)};
            ${w100};
            max-width: 112px;
            border-color: ${theme.colors.gray[600]} !important;
        `};
`;
import React from "react";
import {useEffect, useState} from "react";
import {css} from "@emotion/react";
import styled from "@emotion/styled";
import {lighten} from "polished";

import {calculateRemSize, w100} from "@pg-design/helpers-css";
import {IFormFieldProps} from "@pg-mono/form";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface IProps extends IFormFieldProps<string, any> {
    label?: JSX.Element | string;
    groupClassName?: string;
    isActive: boolean;
    isNonActive: boolean;
    isDisabled?: boolean;
    propertyConfiguratorInput?: boolean;
}

export const SearchFiltersRoomsCheckbox = (props: IProps) => {
    const [triggerOnAfterChange, setTriggerOnAfterChange] = useState(false);
    useEffect(() => {
        if (typeof props.onAfterChange === "function" && triggerOnAfterChange) {
            setTriggerOnAfterChange(false);
            props.onAfterChange(props.name, props.value);
        }
    }, [props.onAfterChange, triggerOnAfterChange]);

    const onChange = () => {
        if (props.isNonActive) {
            return;
        }

        if (props.isDisabled) {
            return;
        }
        props.onChange(props.name, props.value);
        setTriggerOnAfterChange(false);
    };

    return (
        <CheckboxHolder isActive={props.isActive} isNonActive={props.isNonActive} isDisabled={props.isDisabled} onClick={onChange}>
            <span>{props.label}</span>
        </CheckboxHolder>
    );
};

interface ICheckboxHolder {
    isActive: boolean;
    isNonActive: boolean;
    isDisabled?: boolean;
    propertyConfiguratorInput?: boolean;
}

const CheckboxHolder = styled.div<ICheckboxHolder>`
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    ${(props) =>
        props.isDisabled
            ? css`
                  cursor: not-allowed;
                  color: #ccc;
              `
            : css`
                  cursor: pointer;
              `}
    background: ${(props) => props.isActive && (props.isNonActive ? "#ececec" : props.theme.colors.primary)};

    @media (min-width: ${(props) => props.theme.breakpoints.md}) {
        &:hover {
            background: ${(props) => (props.isActive ? (props.isNonActive ? "#f2f2f2" : lighten(0.05, "#ccc")) : "#f9f9f9")};
        }
    }
    ${({propertyConfiguratorInput, theme}) =>
        propertyConfiguratorInput &&
        css`
            height: ${calculateRemSize(6)};
            ${w100};
            max-width: 112px;
            border-color: ${theme.colors.gray[600]} !important;
        `};
`;
