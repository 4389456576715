/* eslint-disable @typescript-eslint/no-explicit-any */
import {isEmpty} from "@pg-mono/nodash";

import {FormFieldClassMap, FormFieldType} from "./form_interfaces";

const strEmpty = (value: any) => value == null || value === "";

export type QueryValues = Record<string, any>;
/**
 * NOTE: do not add values to query that are empty in form
 * This is because we do not need query params to contain empty keys, which looks ugly and is unnecessary.
 */
export const toQueryValues = (types: FormFieldClassMap, formValues: Record<string, any>): QueryValues => {
    return Object.entries(types).reduce((acc: QueryValues, [key, type]) => {
        switch (type) {
            case FormFieldType.Input:
                return strEmpty(formValues[key]) ? acc : {...acc, [key]: formValues[key]};
            case FormFieldType.Radio:
                return strEmpty(formValues[key]) ? acc : {...acc, [key]: formValues[key]};
            case FormFieldType.Checkbox:
            case FormFieldType.MultiCheckbox:
            case FormFieldType.Select:
            case FormFieldType.SelectString:
                if (formValues[key] == null || formValues[key] === "") {
                    return acc;
                }
                return {
                    ...acc,
                    [key]: formValues[key]
                };
            case FormFieldType.InputRange:
            case FormFieldType.Rooms:
            case FormFieldType.SelectRange:
            case FormFieldType.SelectRangeNumber:
                if (formValues[key] == null) {
                    return acc;
                }
                let query = {...acc};
                const {lower, upper} = formValues[key];
                query = strEmpty(lower) ? query : {...query, [`${key}_0`]: lower};
                query = strEmpty(upper) ? query : {...query, [`${key}_1`]: upper};
                return query;
            case FormFieldType.SearchOfferType:
                if (!Array.isArray(formValues[key]) || isEmpty(formValues[key])) {
                    return acc;
                }
                return {...acc, [key]: formValues[key][0]};
            default:
                throw new Error(`toQueryValues: not implemented for ${key}, formFieldType is ${type}`);
        }
    }, {});
};
/* eslint-disable @typescript-eslint/no-explicit-any */
import {isEmpty} from "@pg-mono/nodash";

import {FormFieldClassMap, FormFieldType} from "./form_interfaces";

const strEmpty = (value: any) => value == null || value === "";

export type QueryValues = Record<string, any>;
/**
 * NOTE: do not add values to query that are empty in form
 * This is because we do not need query params to contain empty keys, which looks ugly and is unnecessary.
 */
export const toQueryValues = (types: FormFieldClassMap, formValues: Record<string, any>): QueryValues => {
    return Object.entries(types).reduce((acc: QueryValues, [key, type]) => {
        switch (type) {
            case FormFieldType.Input:
                return strEmpty(formValues[key]) ? acc : {...acc, [key]: formValues[key]};
            case FormFieldType.Radio:
                return strEmpty(formValues[key]) ? acc : {...acc, [key]: formValues[key]};
            case FormFieldType.Checkbox:
            case FormFieldType.MultiCheckbox:
            case FormFieldType.Select:
            case FormFieldType.SelectString:
                if (formValues[key] == null || formValues[key] === "") {
                    return acc;
                }
                return {
                    ...acc,
                    [key]: formValues[key]
                };
            case FormFieldType.InputRange:
            case FormFieldType.Rooms:
            case FormFieldType.SelectRange:
            case FormFieldType.SelectRangeNumber:
                if (formValues[key] == null) {
                    return acc;
                }
                let query = {...acc};
                const {lower, upper} = formValues[key];
                query = strEmpty(lower) ? query : {...query, [`${key}_0`]: lower};
                query = strEmpty(upper) ? query : {...query, [`${key}_1`]: upper};
                return query;
            case FormFieldType.SearchOfferType:
                if (!Array.isArray(formValues[key]) || isEmpty(formValues[key])) {
                    return acc;
                }
                return {...acc, [key]: formValues[key][0]};
            default:
                throw new Error(`toQueryValues: not implemented for ${key}, formFieldType is ${type}`);
        }
    }, {});
};
