import {IRange} from "@pg-design/inputs-module";
import {range} from "@pg-mono/nodash";
import {isNumber} from "@pg-mono/nodash";
import {pluralize} from "@pg-mono/string-utils";

/**
 * Rooms have special case for rendering ranges. They are displayed (view-data) as an array of numbers (rooms count), but used as a range (api-data).
 * view-data: [1, 2, 3, 4, 5]
 * api-data: {lower: 1, upper: 5}
 *
 * (i) range [1..5] equals range [], because all rooms are selected, so no rooms fits that fine
 * (i) 5 means 5+ so upper bound is opened in this case
 */

export interface IRoomValue {
    lower: number | "";
    upper: number | "";
}

export const MIN_ROOMS = 1;
export const MAX_ROOMS = 5;
const MIN = MIN_ROOMS;
const MAX = MAX_ROOMS;
const defaultRange = range(MIN, MAX + 1);

const getValidLower = (lower: IRoomValue["lower"]) => Math.max(lower || MIN, MIN);
const getValidUpper = (upper: IRoomValue["upper"]) => Math.min(upper || MAX, MAX);
const validateBound = (value: IRoomValue): IRoomValue => {
    const {lower, upper} = value;
    // open bounds
    if (!Number.isFinite(lower) && !Number.isFinite(upper)) {
        // undefined bounds
        return value;
    }
    if (!Number.isFinite(lower) && isNumber(upper, true)) {
        // no left bound
        return upper < MIN
            ? {lower: "", upper: ""} // invalid upper bound
            : upper < MAX
              ? value
              : {lower: MIN, upper: MAX};
    }
    if (!Number.isFinite(upper) && isNumber(lower, true)) {
        // no right bound
        return lower > MAX
            ? {lower: "", upper: ""} // invalid lower bound
            : lower > MIN
              ? value
              : {lower: MIN, upper: MAX};
    }
    // defined bounds
    if (isNumber(upper, true) && isNumber(lower, true)) {
        if (upper < lower) {
            // error bound
            return {lower: "", upper: ""};
        }
        if (lower <= MIN && upper >= MAX) {
            // border bounds
            return {lower: MIN, upper: MAX};
        }
        if (lower <= MIN) {
            // border left bound
            return {lower: "", upper};
        }
        if (upper >= MAX) {
            // border right bound
            return {lower, upper: ""};
        }
    }

    return value;
};

export const getRoomLabel = (value: IRoomValue, defaultLabel: string, hideRoomSuffix = false) => {
    const roomsWho = pluralize(["pokój", "pokoje", "pokoi"]);
    const roomsWhom = pluralize(["pokoju", "pokoi", "pokoi"]);
    const {lower, upper} = value;
    // open bounds
    if (!lower && !upper) {
        return defaultLabel;
    }
    if (!lower) {
        // special case for half-opened minimal range
        return upper === MIN ? `${upper} ${hideRoomSuffix ? "" : roomsWho(upper)}` : `do ${upper} ${hideRoomSuffix ? "" : roomsWhom(upper as number)}`;
    }
    if (!upper) {
        return `od ${lower} ${hideRoomSuffix ? "" : roomsWhom(lower as number)}`;
    }
    // defined bounds
    if (lower === upper) {
        return `${lower} ${hideRoomSuffix ? "" : roomsWho(lower as number)}`;
    }
    const optionalPlus = (v: number) => (v === 5 ? "5+" : v);
    return `${lower} - ${optionalPlus(upper as number)} ${hideRoomSuffix ? "" : roomsWho(upper as number)}`;
};

export const isRoomInRange = (value: IRoomValue, rooms: number, threshold = 0) => {
    const {lower, upper} = value;
    if (!lower && !upper) {
        return false;
    }
    const validLower = getValidLower(lower);
    const validUpper = getValidUpper(upper);
    return validLower - threshold <= rooms && rooms <= validUpper + threshold;
};

export const updateRoomBounds = (inValue: IRoomValue, inOption: number, availableRooms: number[] = defaultRange) => {
    return validateBound(updateRoomBoundsNotValidated(inValue, inOption));
    // helper function
    function updateRoomBoundsNotValidated(value: IRoomValue, option: number): IRoomValue {
        const {lower, upper} = value;
        if (!lower && !upper) {
            return {lower: option, upper: option};
        }
        const validLower = getValidLower(lower);
        const validUpper = getValidUpper(upper);
        if (isRoomInRange(value, option)) {
            // cut the range
            const leftDistance = Math.abs(option - validLower);
            const rightDistance = Math.abs(option - validUpper);
            // cut (left or right) values up to the `option` point
            const isLeftDistanceLower = leftDistance < rightDistance;
            const newLower = isLeftDistanceLower ? getNextAvailableBound(option, 1, availableRooms) : lower;
            const newUpper = isLeftDistanceLower ? upper : getNextAvailableBound(option, -1, availableRooms);

            return isLeftDistanceLower ? {lower: newLower, upper} : {lower, upper: newUpper};
        } else {
            // expand the range => left or right
            return option < validLower ? {lower: option, upper} : {lower, upper: option};
        }
    }
};

const getNextAvailableBound = (option: number, diff: number, availableRooms: number[] = defaultRange): number => {
    const newOption = option + diff;
    if (newOption < MIN_ROOMS || newOption > MAX_ROOMS) {
        return 0;
    }
    const isNewOptionAvailable = availableRooms.some((room: number) => room === newOption);
    if (isNewOptionAvailable) {
        return newOption;
    }
    switch (newOption) {
        case MIN_ROOMS:
            return MIN_ROOMS;
        case MAX_ROOMS:
            return MAX_ROOMS;
        case 0:
        case 6:
            return 0;
        default:
            return getNextAvailableBound(newOption, diff, availableRooms);
    }
};

export const getRoomOptions = () => [
    {value: 1, label: "1"},
    {value: 2, label: "2"},
    {value: 3, label: "3"},
    {value: 4, label: "4"},
    {value: 5, label: "5+"}
];

export const getSingleChoiceBounds = (currentValue: IRange<number | "">, newValue: number): IRange<number | ""> => {
    if (currentValue.lower === newValue || currentValue.upper === newValue) {
        return {lower: "", upper: ""};
    }
    if (newValue === 1) {
        return {lower: "", upper: 1};
    }
    if (newValue === 5) {
        return {lower: 5, upper: ""};
    }
    return {lower: newValue, upper: newValue};
};
import {IRange} from "@pg-design/inputs-module";
import {range} from "@pg-mono/nodash";
import {isNumber} from "@pg-mono/nodash";
import {pluralize} from "@pg-mono/string-utils";

/**
 * Rooms have special case for rendering ranges. They are displayed (view-data) as an array of numbers (rooms count), but used as a range (api-data).
 * view-data: [1, 2, 3, 4, 5]
 * api-data: {lower: 1, upper: 5}
 *
 * (i) range [1..5] equals range [], because all rooms are selected, so no rooms fits that fine
 * (i) 5 means 5+ so upper bound is opened in this case
 */

export interface IRoomValue {
    lower: number | "";
    upper: number | "";
}

export const MIN_ROOMS = 1;
export const MAX_ROOMS = 5;
const MIN = MIN_ROOMS;
const MAX = MAX_ROOMS;
const defaultRange = range(MIN, MAX + 1);

const getValidLower = (lower: IRoomValue["lower"]) => Math.max(lower || MIN, MIN);
const getValidUpper = (upper: IRoomValue["upper"]) => Math.min(upper || MAX, MAX);
const validateBound = (value: IRoomValue): IRoomValue => {
    const {lower, upper} = value;
    // open bounds
    if (!Number.isFinite(lower) && !Number.isFinite(upper)) {
        // undefined bounds
        return value;
    }
    if (!Number.isFinite(lower) && isNumber(upper, true)) {
        // no left bound
        return upper < MIN
            ? {lower: "", upper: ""} // invalid upper bound
            : upper < MAX
              ? value
              : {lower: MIN, upper: MAX};
    }
    if (!Number.isFinite(upper) && isNumber(lower, true)) {
        // no right bound
        return lower > MAX
            ? {lower: "", upper: ""} // invalid lower bound
            : lower > MIN
              ? value
              : {lower: MIN, upper: MAX};
    }
    // defined bounds
    if (isNumber(upper, true) && isNumber(lower, true)) {
        if (upper < lower) {
            // error bound
            return {lower: "", upper: ""};
        }
        if (lower <= MIN && upper >= MAX) {
            // border bounds
            return {lower: MIN, upper: MAX};
        }
        if (lower <= MIN) {
            // border left bound
            return {lower: "", upper};
        }
        if (upper >= MAX) {
            // border right bound
            return {lower, upper: ""};
        }
    }

    return value;
};

export const getRoomLabel = (value: IRoomValue, defaultLabel: string, hideRoomSuffix = false) => {
    const roomsWho = pluralize(["pokój", "pokoje", "pokoi"]);
    const roomsWhom = pluralize(["pokoju", "pokoi", "pokoi"]);
    const {lower, upper} = value;
    // open bounds
    if (!lower && !upper) {
        return defaultLabel;
    }
    if (!lower) {
        // special case for half-opened minimal range
        return upper === MIN ? `${upper} ${hideRoomSuffix ? "" : roomsWho(upper)}` : `do ${upper} ${hideRoomSuffix ? "" : roomsWhom(upper as number)}`;
    }
    if (!upper) {
        return `od ${lower} ${hideRoomSuffix ? "" : roomsWhom(lower as number)}`;
    }
    // defined bounds
    if (lower === upper) {
        return `${lower} ${hideRoomSuffix ? "" : roomsWho(lower as number)}`;
    }
    const optionalPlus = (v: number) => (v === 5 ? "5+" : v);
    return `${lower} - ${optionalPlus(upper as number)} ${hideRoomSuffix ? "" : roomsWho(upper as number)}`;
};

export const isRoomInRange = (value: IRoomValue, rooms: number, threshold = 0) => {
    const {lower, upper} = value;
    if (!lower && !upper) {
        return false;
    }
    const validLower = getValidLower(lower);
    const validUpper = getValidUpper(upper);
    return validLower - threshold <= rooms && rooms <= validUpper + threshold;
};

export const updateRoomBounds = (inValue: IRoomValue, inOption: number, availableRooms: number[] = defaultRange) => {
    return validateBound(updateRoomBoundsNotValidated(inValue, inOption));
    // helper function
    function updateRoomBoundsNotValidated(value: IRoomValue, option: number): IRoomValue {
        const {lower, upper} = value;
        if (!lower && !upper) {
            return {lower: option, upper: option};
        }
        const validLower = getValidLower(lower);
        const validUpper = getValidUpper(upper);
        if (isRoomInRange(value, option)) {
            // cut the range
            const leftDistance = Math.abs(option - validLower);
            const rightDistance = Math.abs(option - validUpper);
            // cut (left or right) values up to the `option` point
            const isLeftDistanceLower = leftDistance < rightDistance;
            const newLower = isLeftDistanceLower ? getNextAvailableBound(option, 1, availableRooms) : lower;
            const newUpper = isLeftDistanceLower ? upper : getNextAvailableBound(option, -1, availableRooms);

            return isLeftDistanceLower ? {lower: newLower, upper} : {lower, upper: newUpper};
        } else {
            // expand the range => left or right
            return option < validLower ? {lower: option, upper} : {lower, upper: option};
        }
    }
};

const getNextAvailableBound = (option: number, diff: number, availableRooms: number[] = defaultRange): number => {
    const newOption = option + diff;
    if (newOption < MIN_ROOMS || newOption > MAX_ROOMS) {
        return 0;
    }
    const isNewOptionAvailable = availableRooms.some((room: number) => room === newOption);
    if (isNewOptionAvailable) {
        return newOption;
    }
    switch (newOption) {
        case MIN_ROOMS:
            return MIN_ROOMS;
        case MAX_ROOMS:
            return MAX_ROOMS;
        case 0:
        case 6:
            return 0;
        default:
            return getNextAvailableBound(newOption, diff, availableRooms);
    }
};

export const getRoomOptions = () => [
    {value: 1, label: "1"},
    {value: 2, label: "2"},
    {value: 3, label: "3"},
    {value: 4, label: "4"},
    {value: 5, label: "5+"}
];

export const getSingleChoiceBounds = (currentValue: IRange<number | "">, newValue: number): IRange<number | ""> => {
    if (currentValue.lower === newValue || currentValue.upper === newValue) {
        return {lower: "", upper: ""};
    }
    if (newValue === 1) {
        return {lower: "", upper: 1};
    }
    if (newValue === 5) {
        return {lower: 5, upper: ""};
    }
    return {lower: newValue, upper: newValue};
};
