import React from "react";

import {ApplicationModalButton} from "../../../application/components/ApplicationModalButton";
import {ApplicationSource, ApplicationSourceSection} from "../../../application/utils/ApplicationSource";
import {getOfferDisplayStatus} from "../../../offer/utils/get_offer_display_status";
import {IPropertyListProperty} from "../../../property/types/PropertyListProperty";
import {getPropertyBadgeType} from "../../../property/utils/get_property_badge_type";
import {IPropertyConfiguratorProperty} from "../../../property_configurator/types/IPropertyConfiguratorProperty";
import {gtmEventPropertyListClick} from "../../../tracking/google_tag_manager/gtm_event_property_list";
import {getCurrency} from "../../../utils/get_currency";
import {AskForPropertyButton} from "./AskForPropertyButton";
import {PromotionButton} from "./PromotionButton";
import {ReservedButton} from "./ReservedButton";

interface IProps {
    property: IPropertyListApplicationButtonProperty;
    isAutoresponderEnabled: boolean;
    vendorId: number;
    applicationSourceSection?: ApplicationSourceSection;
    isPlansList?: boolean;
    className?: string;
    isInPropertyConfigurator?: boolean;
}

type IPropertyListApplicationButtonProperty = IPropertyListProperty | IPropertyConfiguratorProperty;

export const PropertyListApplicationButton = (props: IProps) => {
    const {applicationSourceSection, property, vendorId, isPlansList, isInPropertyConfigurator} = props;

    const applicationSourceSectionData = applicationSourceSection || ApplicationSourceSection.BODY;
    const forceShowPrice = property.offer.vendor.configuration.force_show_price;
    const propertyBadgeType = getPropertyBadgeType(property);
    const isSaleOverLimit = getOfferDisplayStatus(property.offer) === "limited";

    const onOpen = () => {
        if (property.is_reserved && !isPlansList) {
            gtmEventPropertyListClick("rezerwacja");
        } else if (!property.discount) {
            gtmEventPropertyListClick("zapytaj o cene");
        }
    };

    return (
        <ApplicationModalButton
            source={getSource(property, isPlansList, isInPropertyConfigurator, forceShowPrice)}
            onOpen={onOpen}
            render={({openModal}) => {
                if (propertyBadgeType === "promotion" && !isPlansList) {
                    return <PromotionButton onClick={openModal} property={property} showPrice={forceShowPrice} />;
                }

                if (propertyBadgeType === "reservation" && !isPlansList) {
                    return <ReservedButton onClick={openModal} />;
                }

                return (
                    <AskForPropertyButton
                        onClick={openModal}
                        twoPercentCreditAvailable={property.credit_two_percent}
                        isPlansList={isPlansList}
                        isInPropertyConfigurator={isInPropertyConfigurator}
                        price={property.price}
                        priceType={property.price_type ?? property.offer.price_type}
                        showPrice={forceShowPrice}
                        currency={getCurrency({currency: property.offer.currency})}
                    />
                );
            }}
            sourceSection={applicationSourceSectionData}
            vendorId={vendorId}
            offerId={property.offer.id}
            propertyId={property.id}
            forceClose={isSaleOverLimit}
        />
    );
};

//  Utils
function getSource(property: IPropertyListApplicationButtonProperty, isPlansList?: boolean, isInPropertyConfigurator?: boolean, forceShowPrice?: boolean) {
    if (forceShowPrice || (property.configuration.show_price && !!property.price)) {
        return ApplicationSource.Property;
    }

    if (isInPropertyConfigurator) {
        return ApplicationSource.PropertyConfigurator;
    }

    if (property.discount && !isPlansList) {
        return ApplicationSource.Promotion;
    }

    if (property.is_reserved && !isPlansList) {
        return ApplicationSource.Reservation;
    }

    return ApplicationSource.PriceInquiry;
}
import React from "react";

import {ApplicationModalButton} from "../../../application/components/ApplicationModalButton";
import {ApplicationSource, ApplicationSourceSection} from "../../../application/utils/ApplicationSource";
import {getOfferDisplayStatus} from "../../../offer/utils/get_offer_display_status";
import {IPropertyListProperty} from "../../../property/types/PropertyListProperty";
import {getPropertyBadgeType} from "../../../property/utils/get_property_badge_type";
import {IPropertyConfiguratorProperty} from "../../../property_configurator/types/IPropertyConfiguratorProperty";
import {gtmEventPropertyListClick} from "../../../tracking/google_tag_manager/gtm_event_property_list";
import {getCurrency} from "../../../utils/get_currency";
import {AskForPropertyButton} from "./AskForPropertyButton";
import {PromotionButton} from "./PromotionButton";
import {ReservedButton} from "./ReservedButton";

interface IProps {
    property: IPropertyListApplicationButtonProperty;
    isAutoresponderEnabled: boolean;
    vendorId: number;
    applicationSourceSection?: ApplicationSourceSection;
    isPlansList?: boolean;
    className?: string;
    isInPropertyConfigurator?: boolean;
}

type IPropertyListApplicationButtonProperty = IPropertyListProperty | IPropertyConfiguratorProperty;

export const PropertyListApplicationButton = (props: IProps) => {
    const {applicationSourceSection, property, vendorId, isPlansList, isInPropertyConfigurator} = props;

    const applicationSourceSectionData = applicationSourceSection || ApplicationSourceSection.BODY;
    const forceShowPrice = property.offer.vendor.configuration.force_show_price;
    const propertyBadgeType = getPropertyBadgeType(property);
    const isSaleOverLimit = getOfferDisplayStatus(property.offer) === "limited";

    const onOpen = () => {
        if (property.is_reserved && !isPlansList) {
            gtmEventPropertyListClick("rezerwacja");
        } else if (!property.discount) {
            gtmEventPropertyListClick("zapytaj o cene");
        }
    };

    return (
        <ApplicationModalButton
            source={getSource(property, isPlansList, isInPropertyConfigurator, forceShowPrice)}
            onOpen={onOpen}
            render={({openModal}) => {
                if (propertyBadgeType === "promotion" && !isPlansList) {
                    return <PromotionButton onClick={openModal} property={property} showPrice={forceShowPrice} />;
                }

                if (propertyBadgeType === "reservation" && !isPlansList) {
                    return <ReservedButton onClick={openModal} />;
                }

                return (
                    <AskForPropertyButton
                        onClick={openModal}
                        twoPercentCreditAvailable={property.credit_two_percent}
                        isPlansList={isPlansList}
                        isInPropertyConfigurator={isInPropertyConfigurator}
                        price={property.price}
                        priceType={property.price_type ?? property.offer.price_type}
                        showPrice={forceShowPrice}
                        currency={getCurrency({currency: property.offer.currency})}
                    />
                );
            }}
            sourceSection={applicationSourceSectionData}
            vendorId={vendorId}
            offerId={property.offer.id}
            propertyId={property.id}
            forceClose={isSaleOverLimit}
        />
    );
};

//  Utils
function getSource(property: IPropertyListApplicationButtonProperty, isPlansList?: boolean, isInPropertyConfigurator?: boolean, forceShowPrice?: boolean) {
    if (forceShowPrice || (property.configuration.show_price && !!property.price)) {
        return ApplicationSource.Property;
    }

    if (isInPropertyConfigurator) {
        return ApplicationSource.PropertyConfigurator;
    }

    if (property.discount && !isPlansList) {
        return ApplicationSource.Promotion;
    }

    if (property.is_reserved && !isPlansList) {
        return ApplicationSource.Reservation;
    }

    return ApplicationSource.PriceInquiry;
}
