import {SortOptionsValues} from "../../offer/detail/types/SortOptionsValues";

export const propertyListSortOptions = [
    {label: "Najniższa cena", value: SortOptionsValues.LOWEST_PRICE, slug: "price"},
    {label: "Najwyższa cena", value: SortOptionsValues.HIGHEST_PRICE, slug: "-price"},
    {label: "Najniższe piętro", value: SortOptionsValues.LOWEST_FLOOR, slug: "floor"},
    {label: "Najwyższe piętro", value: SortOptionsValues.HIGHEST_FLOOR, slug: "-floor"},
    {label: "Najmniejsza liczba pokoi", value: SortOptionsValues.LOWEST_ROOMS_NUMBER, slug: "rooms"},
    {label: "Największa liczba pokoi", value: SortOptionsValues.HIGHEST_ROOMS_NUMBER, slug: "-rooms"}
];
import {SortOptionsValues} from "../../offer/detail/types/SortOptionsValues";

export const propertyListSortOptions = [
    {label: "Najniższa cena", value: SortOptionsValues.LOWEST_PRICE, slug: "price"},
    {label: "Najwyższa cena", value: SortOptionsValues.HIGHEST_PRICE, slug: "-price"},
    {label: "Najniższe piętro", value: SortOptionsValues.LOWEST_FLOOR, slug: "floor"},
    {label: "Najwyższe piętro", value: SortOptionsValues.HIGHEST_FLOOR, slug: "-floor"},
    {label: "Najmniejsza liczba pokoi", value: SortOptionsValues.LOWEST_ROOMS_NUMBER, slug: "rooms"},
    {label: "Największa liczba pokoi", value: SortOptionsValues.HIGHEST_ROOMS_NUMBER, slug: "-rooms"}
];
