import React from "react";
import classNames from "classnames";

import {RangeType} from "./constants";
import {IRange} from "./create_range";

import {optionElementStyle, optionWrapperStyle} from "./InputRangeOptions.module.css";

interface IProps {
    options?: {value: number; label: string}[];
    value?: IRange<RangeType>;
    name: string;
    cutDirection: number;
    onChange: (name: string, value: RangeType) => void;
    onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
    className?: string;
}

export const InputRangeOptions = (props: IProps) => {
    const {name, value, cutDirection, options = []} = props;
    const isLower = cutDirection > 0;
    const isUpper = cutDirection < 0;

    let currentValue: RangeType | "";
    let oppositeFieldValue: RangeType | "";

    if (options && value && cutDirection !== 0) {
        if (isLower) {
            currentValue = value.lower;
            oppositeFieldValue = value.upper;
        } else {
            currentValue = value.upper;
            oppositeFieldValue = value.lower;
        }
    }

    return options.length > 0 ? (
        <div className={classNames(optionWrapperStyle, props.className)}>
            {options.map((option: {label: string; value: RangeType}) => {
                let disabled = false;

                const isAbove = isLower && Number(oppositeFieldValue) <= Number(option.value);
                const isBelow = isUpper && Number(oppositeFieldValue) >= Number(option.value);

                let optionLabel: string | JSX.Element = option.label;
                if ((currentValue as string | number).toString() === option.value.toString()) {
                    optionLabel = <b>{option.label}</b>;
                } else {
                    if (oppositeFieldValue !== "" && (isAbove || isBelow)) {
                        disabled = true;
                        optionLabel = <span>{option.label}</span>;
                    }
                }

                const onClick = (event: React.MouseEvent<HTMLDivElement>) => {
                    if (!disabled) {
                        props.onChange(name, option.value);
                    }

                    props.onClick?.(event);
                };
                return oppositeFieldValue !== "" && (isAbove || isBelow) ? null : (
                    <div className={optionElementStyle} key={option.value.toString()} onClick={onClick}>
                        {optionLabel}
                    </div>
                );
            })}
        </div>
    ) : null;
};
import React from "react";
import classNames from "classnames";

import {RangeType} from "./constants";
import {IRange} from "./create_range";

import {optionElementStyle, optionWrapperStyle} from "./InputRangeOptions.module.css";

interface IProps {
    options?: {value: number; label: string}[];
    value?: IRange<RangeType>;
    name: string;
    cutDirection: number;
    onChange: (name: string, value: RangeType) => void;
    onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
    className?: string;
}

export const InputRangeOptions = (props: IProps) => {
    const {name, value, cutDirection, options = []} = props;
    const isLower = cutDirection > 0;
    const isUpper = cutDirection < 0;

    let currentValue: RangeType | "";
    let oppositeFieldValue: RangeType | "";

    if (options && value && cutDirection !== 0) {
        if (isLower) {
            currentValue = value.lower;
            oppositeFieldValue = value.upper;
        } else {
            currentValue = value.upper;
            oppositeFieldValue = value.lower;
        }
    }

    return options.length > 0 ? (
        <div className={classNames(optionWrapperStyle, props.className)}>
            {options.map((option: {label: string; value: RangeType}) => {
                let disabled = false;

                const isAbove = isLower && Number(oppositeFieldValue) <= Number(option.value);
                const isBelow = isUpper && Number(oppositeFieldValue) >= Number(option.value);

                let optionLabel: string | JSX.Element = option.label;
                if ((currentValue as string | number).toString() === option.value.toString()) {
                    optionLabel = <b>{option.label}</b>;
                } else {
                    if (oppositeFieldValue !== "" && (isAbove || isBelow)) {
                        disabled = true;
                        optionLabel = <span>{option.label}</span>;
                    }
                }

                const onClick = (event: React.MouseEvent<HTMLDivElement>) => {
                    if (!disabled) {
                        props.onChange(name, option.value);
                    }

                    props.onClick?.(event);
                };
                return oppositeFieldValue !== "" && (isAbove || isBelow) ? null : (
                    <div className={optionElementStyle} key={option.value.toString()} onClick={onClick}>
                        {optionLabel}
                    </div>
                );
            })}
        </div>
    ) : null;
};
