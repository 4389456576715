import {IPropertyListProperty} from "../types/PropertyListProperty";

export function getPropertyBadgeType(property: IPropertyListProperty) {
    //  TODO: Add source for isRecommended or remove this case if there is a business decisoin to drop it
    const isRecommended = false;

    if (property.is_reserved) {
        return "reservation";
    }

    if (property.discount) {
        return "promotion";
    }

    if (isRecommended) {
        return "recommended";
    }

    return null;
}
import {IPropertyListProperty} from "../types/PropertyListProperty";

export function getPropertyBadgeType(property: IPropertyListProperty) {
    //  TODO: Add source for isRecommended or remove this case if there is a business decisoin to drop it
    const isRecommended = false;

    if (property.is_reserved) {
        return "reservation";
    }

    if (property.discount) {
        return "promotion";
    }

    if (isRecommended) {
        return "recommended";
    }

    return null;
}
