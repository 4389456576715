import React from "react";
import {css, useTheme} from "@emotion/react";
import styled from "@emotion/styled";

import {onDesktop} from "@pg-design/helpers-css";
import {PercentIcon} from "@pg-design/icons-module";
import {Text} from "@pg-design/text-module";
import {priceFormat} from "@pg-mono/string-utils";

import {IPropertyDiscountPriceFields, isPropertyWithDiscountPrice} from "../../../property/utils/is_property_with_discount_price";
import {IPropertyListIconButtonProps, PropertyListIconButton} from "../PropertyListIconButton";

interface IProps {
    property: IPropertyDiscountPriceFields;
    onClick?: (event: React.MouseEvent) => void;
    showPrice?: boolean;
}

export function PromotionButton(props: IProps) {
    const {property} = props;

    const theme = useTheme();

    const hasDiscountPriceInfo = isPropertyWithDiscountPrice(property);

    const onClick = (event: React.MouseEvent) => {
        event.stopPropagation();

        props.onClick?.(event);
    };

    const renderPriceInfo = () => {
        if (props.showPrice || hasDiscountPriceInfo) {
            return (
                <>
                    <br />
                    <span data-testid="icon-button-price">{priceFormat(property.price as number)}</span>
                    {hasDiscountPriceInfo && (
                        <>
                            <br />
                            <Text as="span" variant="info_txt_2" color={theme.colors.gray[700]} css={discountText}>
                                Najniższa cena z ostatnich 30 dni, przed obniżką: <strong>{priceFormat(property.lowest_price_within_30_days as number)}</strong>
                            </Text>
                        </>
                    )}
                </>
            );
        }

        return null;
    };

    return (
        <PropertyListIconButtonStyled
            hasDiscountPriceInfo={hasDiscountPriceInfo}
            icon={<PercentIcon size="1.2" wrapperColor={theme.colors.info} wrapperSize="2" wrapperType="circle" />}
            onClick={onClick}
        >
            Promocja {renderPriceInfo()}
        </PropertyListIconButtonStyled>
    );
}

//  Styles
interface IPropertyListIconButtonStyled extends IPropertyListIconButtonProps {
    hasDiscountPriceInfo: boolean;
}

const PropertyListIconButtonStyled = styled(PropertyListIconButton)<IPropertyListIconButtonStyled>`
    ${({hasDiscountPriceInfo}) =>
        hasDiscountPriceInfo &&
        css`
            align-items: start;
        `}
`;

const discountText = css`
    display: inline-block;
    max-width: 78px;

    ${onDesktop(css`
        max-width: 136px;
    `)}
`;
import React from "react";
import {css, useTheme} from "@emotion/react";
import styled from "@emotion/styled";

import {onDesktop} from "@pg-design/helpers-css";
import {PercentIcon} from "@pg-design/icons-module";
import {Text} from "@pg-design/text-module";
import {priceFormat} from "@pg-mono/string-utils";

import {IPropertyDiscountPriceFields, isPropertyWithDiscountPrice} from "../../../property/utils/is_property_with_discount_price";
import {IPropertyListIconButtonProps, PropertyListIconButton} from "../PropertyListIconButton";

interface IProps {
    property: IPropertyDiscountPriceFields;
    onClick?: (event: React.MouseEvent) => void;
    showPrice?: boolean;
}

export function PromotionButton(props: IProps) {
    const {property} = props;

    const theme = useTheme();

    const hasDiscountPriceInfo = isPropertyWithDiscountPrice(property);

    const onClick = (event: React.MouseEvent) => {
        event.stopPropagation();

        props.onClick?.(event);
    };

    const renderPriceInfo = () => {
        if (props.showPrice || hasDiscountPriceInfo) {
            return (
                <>
                    <br />
                    <span data-testid="icon-button-price">{priceFormat(property.price as number)}</span>
                    {hasDiscountPriceInfo && (
                        <>
                            <br />
                            <Text as="span" variant="info_txt_2" color={theme.colors.gray[700]} css={discountText}>
                                Najniższa cena z ostatnich 30 dni, przed obniżką: <strong>{priceFormat(property.lowest_price_within_30_days as number)}</strong>
                            </Text>
                        </>
                    )}
                </>
            );
        }

        return null;
    };

    return (
        <PropertyListIconButtonStyled
            hasDiscountPriceInfo={hasDiscountPriceInfo}
            icon={<PercentIcon size="1.2" wrapperColor={theme.colors.info} wrapperSize="2" wrapperType="circle" />}
            onClick={onClick}
        >
            Promocja {renderPriceInfo()}
        </PropertyListIconButtonStyled>
    );
}

//  Styles
interface IPropertyListIconButtonStyled extends IPropertyListIconButtonProps {
    hasDiscountPriceInfo: boolean;
}

const PropertyListIconButtonStyled = styled(PropertyListIconButton)<IPropertyListIconButtonStyled>`
    ${({hasDiscountPriceInfo}) =>
        hasDiscountPriceInfo &&
        css`
            align-items: start;
        `}
`;

const discountText = css`
    display: inline-block;
    max-width: 78px;

    ${onDesktop(css`
        max-width: 136px;
    `)}
`;
